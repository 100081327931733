import { useState } from 'react';

export default function useLocalStorage(localItem) {
  const [loc, setState] = useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localItem)
  );

  function setLoc(newItem) {
    const windowGlobal = typeof window !== 'undefined' && window;
    windowGlobal.localStorage.setItem(localItem, newItem);
    setState(newItem);
  }

  return [loc, setLoc];
}
