import styled from 'styled-components';
import { themeGet } from 'styled-system';

const FooterWrapper = styled.footer`
  width: 100%;
  padding: 5%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${themeGet('color.1')};
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;

  @media print {
    display: none;
  }

  ul {
    margin: 0;
  }

  ul.footer-menu {
    > li.parent-item,
    > li.menu-item {
      margin-bottom: 40px;
      @media only screen and (max-width: 667px) {
        margin-bottom: 30px;
      }
    }
  }
`;

export const FooterMenuArea = styled.div`
  width: 100%;
  flex: 1;
  ul.footer-menu {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    @media only screen and (max-width: 480px) {
      flex-direction: column;
      justify-content: flex-start;
    }
    li {
      list-style: none;
      a {
        display: flex;
        color: ${themeGet('color.2')};
        padding: 0.5em 0;
        font-size: 16px;
      }
      &.parent-item {
        > a {
          color: ${themeGet('color.2')};
          font-size: 1.5rem;
        }
      }
      ul.footer-submenu {
        li {
        }
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  a.button.primary {
    font-size: 12px;
    margin-top: 10px;
    justify-content: center;
    padding: 0.8em 1em;
  }
`;

export default FooterWrapper;
