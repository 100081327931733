const dark = {
  color: [
    '#0e0e0e', // 0: primary bg
    '#040404', // 1: header footer bg
    '#ffffff', // 2: primary text
    '#fff689', // 3: text link color
    '#a6f0ff', // 4: meta text color
    'rgb(36, 36, 36)', // 5: submenu bg
    'rgb(70, 69, 70)', // 6: submenu has submenu bg
    '#242424', // 7: search input bg and border
    '#ffffff', // 8: white
    '#000000', // 9: black
  ],
  text: [],
  border: [],
  boxShadow: [],
  fonts: {
    primary: 'Lato, sans-serif',
    secondary: 'myriad-pro, sans-serif',
    mono: 'fira-mono, monospace',
  },
};

export default dark;
