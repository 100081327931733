import styled from 'styled-components';
import { themeGet } from 'styled-system';

const LayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > main {
    flex: 1 1 auto;
    padding-top: 72px;
    background-color: ${themeGet('color.0')};
    color: ${themeGet('color.2')};
    overflow: hidden;

    &.post-layout {
      padding-top: 0;
    }

    /* single page content */
    .page-paragraph,
    .post-intro,
    .post-paragraph {
      width: 80%;
      max-width: 800px;
      margin: auto;
      padding-bottom: 5%;

      @media only screen and (max-width: 480px) {
        width: 100%;
        padding: 0 20px;
      }
    }

    .post-intro {
      font-style: italic;
      text-align: center;
      font-size: 1.7rem;
      padding: 2em 0;
    }

    /* home page style */
    .home-page {
      padding: 25px;
      display: flex;

      @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
        flex-direction: column;
      }

      .content {
        width: calc(100% - 278px);

        @media only screen and (max-width: 991px) {
          width: calc(100% - 25px);
        }

        @media only screen and (max-width: 767px) {
          width: 100%;
        }

        .items {
          display: flex;
          flex-wrap: wrap;

          .content-card {
            width: calc(100% / 2 - 25px);
            margin-right: 25px;
            margin-bottom: 25px;
            min-height: 300px;
            height: 41vw;

            @media only screen and (max-width: 991px) {
              width: 100%;
            }

            @media only screen and (max-width: 767px) {
              margin-right: 0;
            }

            a {
              height: 100%;
              overflow: hidden;

              h3 {
                @media only screen and (max-width: 1440px) {
                  font-size: 1.6rem;
                }
              }

              img {
                width: 100%;
                height: 100%;
              }
            }

            &:first-child {
              width: 100%;
            }
          }
        }

        @media only screen and (max-width: 991px) {
          .actions {
            padding-top: 23px;
          }
        }
      }

      .sidebar {
        width: 278px;

        @media only screen and (max-width: 767px) {
          width: 100%;
          margin-top: 40px;
        }

        .items {
          height: calc(82vw + 45px);
          overflow: hidden;

          @media only screen and (max-width: 767px) {
            height: auto;
            max-width: 480px;
            overflow: inherit;
          }
        }
      }

      .actions {
        text-align: center;

        @media only screen and (max-width: 1440px) {
          padding-top: 10px;
        }

        @media only screen and (max-width: 1200px) {
          padding-top: 30px;
        }
      }
    }

    .masonry-grid {
      .masonry-grid_column {
        .content-card {
          h3 {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
`;

export default LayoutWrapper;
