import { createGlobalStyle } from 'styled-components';
import { themeGet } from 'styled-system';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

  ::selection {
    background-color: #232323;
    color: #ffffff;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  body {
    overflow-x: hidden;
    
    * {
      box-sizing: border-box;
    }
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    font-family: 'Tinos', serif;
    word-break: break-word;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  h1 {
    font-size: 4rem;
    line-height: 1.2em;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    @media only screen and (max-width: 1200px) {
      font-size: 3rem;
    }

    @media only screen and (max-width: 991px) {
      font-size: 2.4rem;
    }
  }
  
  .page-paragraph h2,
  .post-paragraph h2 {
    font-size: 3rem;
    line-height: 1.2em;
    @media only screen and (max-width: 1200px) {
      font-size: 2.4rem;
    }
    @media only screen and (max-width: 991px) {
      font-size: 1.8rem;
    }
  }
  
  h3 {
    font-size: 2.6rem;
    line-height: 1.2em;
    @media only screen and (max-width: 1200px) {
      font-size: 2rem;
    }
    @media only screen and (max-width: 991px) {
      font-size: 1.5rem;
    }
  }
  
  .page-paragraph h3,
  .post-paragraph h3 {
    padding: 1em 0 0;
  }
  
  h4 {
    font-size: 1.3rem;
    margin: 1em 0;
  }
  
  .page-paragraph h4,
  .post-paragraph h4 {
    font-size: 2rem;
  }
  
  .page-paragraph h5,
  .post-paragraph h5 {
    font-size: 1.5rem;
  }
  
  .page-paragraph h6,
  .post-paragraph h6 {
    font-size: 1.3rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    line-height: 1.7em;
    text-align: justify;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    font-size: 1.3rem;
    margin: 25px 0;

    @media only screen and (max-width: 767px) {
      text-align: left;
    }
  }

  a {
    color: ${themeGet('color.3')};
    text-decoration: none;
  }

  form, input, ol, p, select, textarea, ul {
    font-size: 1.3rem;
    margin: 25px 0;
  }

  // button style
  button,
  .button {
    -webkit-appearance: none;
    padding: 0.8em 1em;
    min-width: 100px;
    box-sizing: border-box;
    background: none;
    border: none;
    outline: none;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s;
    font-family: 'Inter', sans-serif;
    position: relative;
    overflow: hidden;
    font-size: 0.7rem;
    text-align: center;
  }

  button.primary,
  .button.primary {
    background: ${themeGet('color.3')};
    border: 1px solid ${themeGet('color.3')};
    color: ${themeGet('color.1')} !important;
  }

  button.secondary,
  .button.secondary {
    color: ${themeGet('color.3')};
    border: 1px solid ${themeGet('color.3')};
    background: rgba(255, 246, 137, 0);
    transition: 0.3s ease;
  }

  button.secondary:hover,
  .button.secondary:hover {
    color: ${themeGet('color.1')};
    background: ${themeGet('color.3')};
  }

  button.tertiary,
  .button.tertiary {
    background: #242424;
    border: 1px solid #242424;
    color: #ffffff;
  }

  .button-group {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .button-group > * {
    margin: 0 calc(25px / 4);
    display: inline-block;
  }

  .button-group > *:first-child {
    margin-left: 0;
  }

  .button-group > *:last-child {
    margin-right: 0;
  }

  .button-group > form {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .readmore {
    color: ${themeGet('color.3')};
    font-family: 'Inter', sans-serif;
    text-decoration: none;
    margin-top: .5em;
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  em {
    font-style: italic;
  }
  
  strong {
    font-weight: 700;
  }
  
  sup {
    vertical-align: super;
    font-size: 0.8rem;
  }
  
  sub {
    vertical-align: sub;
    font-size: 0.8rem;
  }

  .page-paragraph,
  .post-paragraph {
    ul {
      list-style: disc;
    }
    
    ol {
      list-style: decimal-leading-zero;
    }
    
    ul, ol {
      margin-left: 1.8em;
    }
  }
`;

export default GlobalStyle;
