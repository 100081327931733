import React, { useContext } from 'react';
import { ThemeContext } from '../../context/themeContext';

const ThemeSwitcher = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <button
      className="button secondary"
      type="button"
      onClick={themeContext.themeToggle}
    >
      {themeContext.contextState === 'dark' ? 'Licht thema' : 'Donker thema'}
    </button>
  );
};

export default ThemeSwitcher;
