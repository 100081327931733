const light = {
  color: [
    '#fafafa', // 0: primary bg
    '#ffffff', // 1: header footer bg
    '#040404', // 2: primary text
    '#ff5608', // 3: text link color
    '#0882ff', // 4: meta text color
    'rgb(250, 250, 250)', // 5: submenu bg
    'rgb(238, 238, 238)', // 6: submenu has submenu bg
    '#eee', // 7: search input bg and border
    '#ffffff', // 8: white
    '#000000', // 9: black
  ],
  text: [],
  border: [],
  boxShadow: [],
  fonts: {
    primary: 'Lato, sans-serif',
    secondary: 'myriad-pro, sans-serif',
    mono: 'fira-mono, monospace',
  },
};

export default light;
