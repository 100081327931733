import React from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

const ThemeContext = React.createContext({});

const ContextProvider = ({ children }) => {
  const [contextState = 'dark', setContextState] = useLocalStorage('novTheme');

  const themeToggle = () => {
    setContextState(contextState === 'dark' ? 'light' : 'dark');
  };

  return (
    <ThemeContext.Provider
      value={{
        contextState,
        setContextState,
        themeToggle,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ContextProvider, ThemeContext };
