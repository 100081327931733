import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';
import FooterWrapper, { FooterMenuArea, ButtonGroup } from './Footer.style';

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              id
              name
              slug
              items {
                url
                title
                object_id
                object_slug
                wordpress_children {
                  url
                  title
                  object_id
                  object_slug
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const footerMenuItems =
        data.allWordpressWpApiMenusMenusItems.edges[0].node.items;

      return (
        <FooterWrapper>
          <FooterMenuArea>
            <ul className="footer-menu">
              {footerMenuItems.map(item => {
                let menuUrl = item.url;

                // menu for external link
                if (
                  item.url.includes('https') &&
                  !item.url.includes('https://nov.covey.cloud')
                ) {
                  return (
                    <li
                      key={`fmenu-item${item.object_id}`}
                      className={
                        item.wordpress_children ? 'parent-item' : 'menu-item'
                      }
                    >
                      <a
                        href={menuUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.title}
                      </a>

                      {item.wordpress_children && (
                        <ul className="footer-submenu">
                          {item.wordpress_children.map(subitem => (
                            <li
                              key={`fmenu-subitem${subitem.object_id}`}
                              className={
                                subitem.wordpress_children
                                  ? 'parent-item'
                                  : 'menu-item'
                              }
                            >
                              <a
                                href={subitem.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {subitem.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                }

                // menu link for without slug
                if (
                  item.url.includes('https') &&
                  item.url.includes('https://nov.covey.cloud')
                ) {
                  menuUrl = new URL(item.url).pathname;
                }

                return (
                  <li
                    key={`fmenu-item${item.object_id}`}
                    className={
                      item.wordpress_children ? 'parent-item' : 'menu-item'
                    }
                  >
                    <Link to={menuUrl}>{item.title}</Link>
                    {item.wordpress_children && (
                      <ul className="footer-submenu">
                        {item.wordpress_children.map(subitem => {
                          let subMenuItemUrl = subitem.url;

                          if (
                            subitem.url.includes('https') &&
                            subitem.url.includes('https://nov.covey.cloud')
                          ) {
                            subMenuItemUrl = new URL(subMenuItemUrl).pathname;
                          }

                          return (
                            <li
                              key={`fmenu-subitem${subitem.object_id}`}
                              className={
                                subitem.wordpress_children
                                  ? 'parent-item'
                                  : 'menu-item'
                              }
                            >
                              {subMenuItemUrl.indexOf('http') > -1 ? (
                                <a
                                  href={subMenuItemUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {subitem.title}
                                </a>
                              ) : (
                                <Link to={subMenuItemUrl}>{subitem.title}</Link>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
              <li className="menu-item">
                <ButtonGroup>
                  <ThemeSwitcher />
                  <Link to="/lidworden" className="button primary ">
                    Lid Worden
                  </Link>
                </ButtonGroup>
              </li>
            </ul>
          </FooterMenuArea>
        </FooterWrapper>
      );
    }}
  />
);

export default Footer;
